import { atom } from "jotai"
import moment from "moment"
import { dateRangeRows } from "../components/DateRangePicker/QuickOptionPicker"
import { getLocalStorageValueByKey, localStorageKeys, setLocalStorageValueByKey } from "../hooks/LocalStorageHooks"

export const getDefaultFilter = () => {
	let localStorageValue = getLocalStorageValueByKey(localStorageKeys.HEADER_DATE)

	// If selection is what was last used, then update the dates to be correct in case the date has ticked over
	if (localStorageValue && localStorageValue.selectionString) {
		const newVals = dateRangeRows.find((x) => x.title === localStorageValue.selectionString)

		if (newVals) {
			localStorageValue = { ...localStorageValue, dateFrom: newVals.dateRange.dateFrom, dateTo: newVals.dateRange.dateTo }
			setLocalStorageValueByKey(localStorageKeys.HEADER_DATE, localStorageValue)
		}
	}

	if (localStorageValue) return localStorageValue

	const startDate = moment()
	const endDate = moment()

	return { dateFrom: startDate.subtract(1, "years").format("YYYY-MM-DD"), dateTo: endDate.format("YYYY-MM-DD"), selectionString: "Last 12 months", useActivityDate: true }
}

export const filterAtom = atom(getDefaultFilter())
