import { ACTIVITY_TOTAL_ENDPOINT, LATEST_API_VERSION, EMISSIONS_OVERVIEW_ENDPOINT } from "../../util/Constants"
import { transformArrayOfObjectsForKPICard, transformArrayOfArraysForKPICard } from "../../lib/KPICardHelper"
import VisxChartWrapper from "../../components/ChartsVisX/shared/VisxChartWrapper"
import { getThisLastDateRange } from "../../util/util"
import { useFilterAtomValue } from "../../hooks/FilterHooks"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { LayoutGrid } from "../../layoutBuilder/LayoutGrid"

export const DashboardKPIEndpoint = () => {
	const layoutItemProps = {
		lg: 2,
		xl: 2,
		minHeight: 220,
		elevation: 0,
	}

	const { partners } = setQueryParameters()
	const { partnerCodes } = setQueryParameters("POST")
	const filterDataValue = useFilterAtomValue()
	const dateRangeCalc = getThisLastDateRange(filterDataValue)
	const queryDataForActivityTotals = [
		{
			method: "get",
			url: ACTIVITY_TOTAL_ENDPOINT,
			params: { "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.this.dateFrom, dateTo: dateRangeCalc.this.dateTo, partners },
		},
		{
			method: "get",
			url: ACTIVITY_TOTAL_ENDPOINT,
			params: { "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.last.dateFrom, dateTo: dateRangeCalc.last.dateTo, partners },
		},
	]

	const trendUnit = filterDataValue.selectionString ? `vs. ${filterDataValue.selectionString.toLowerCase()}` : `vs. prev ${filterDataValue.daysBetween}d`

	const KPICardConfig = [
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Emissions (tCO2e)",
			icon: null,
			queryData: [
				{
					url: EMISSIONS_OVERVIEW_ENDPOINT,
					method: "get",
					params: { partners, "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.this.dateFrom, dateTo: dateRangeCalc.this.dateTo },
				},
				{
					url: EMISSIONS_OVERVIEW_ENDPOINT,
					method: "get",
					params: { partners, "api-version": LATEST_API_VERSION, dateFrom: dateRangeCalc.last.dateFrom, dateTo: dateRangeCalc.last.dateTo },
				},
			],
			dataTransformFunction: (data) => transformArrayOfArraysForKPICard(data, ["air", "road", "sea", "rail", "accommodation"], "emissions.total"),
			tooltipContent: "",
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Flights (km)",
			icon: null,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["air.passengerFlights"]),
			tooltipContent: "",
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Stays (Room nights)",
			icon: null,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["accommodation.roomNights"]),
			tooltipContent: "",
		},
		{
			unit: "",
			trendUnit,
			trendSymbol: "%",
			title: "Cars (Rental days)",
			icon: null,
			queryData: queryDataForActivityTotals,
			dataTransformFunction: (data) => transformArrayOfObjectsForKPICard(data, ["road.totalRentalDays"]),
			tooltipContent: "",
		},
	]

	return (
		<>
			{KPICardConfig.map((item, i) => {
				const { title, trendUnit, trendSymbol } = item

				return (
					<VisxChartWrapper key={i} queryData={item.queryData} dataTransformFunction={item.dataTransformFunction}>
						{({ transformedData }) => (
							<>
								{transformedData && "value" in transformedData && (
									<LayoutGrid>
										<Table {...layoutItemProps}>
											<caption>{item.queryData.url}</caption>
											<TableHead>
												<TableRow>
													<TableCell colSpan="3">{title}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>{transformedData.value}</TableCell>
													<TableCell>
														{transformedData.trend} {trendSymbol}
													</TableCell>
													<TableCell>{trendUnit}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</LayoutGrid>
								)}
							</>
						)}
					</VisxChartWrapper>
				)
			})}
		</>
	)
}
