import { API_VERSION_PARAM, BACKEND_BASE_URL, RETIREMENTS_CERTIFICATES_ENDPOINT } from "../util/Constants"
import moment from "moment"
import axios from "axios"
import { ErrorHandling } from "../components/Error"
import { dateRangeRows } from "../components/DateRangePicker/QuickOptionPicker"

export const isDoneLoading = ({ loaded, error }) => loaded && !error

export function getStartEndDate(monthsAgo = 12, endOffset = 0) {
	const today = new Date()
	const dateFrom = moment(today)
		.subtract(monthsAgo + endOffset, "months")
		.format("yyyy-MM-DD")
	const dateTo = moment(today).subtract(endOffset, "months").format("yyyy-MM-DD")
	return { dateFrom, dateTo }
}

export function getStartEndUnit(unit, unitsToAdd = 0) {
	const today = moment().add(unitsToAdd, unit)
	const dateFrom = moment(today).startOf(unit).format("yyyy-MM-DD")
	const dateTo = moment(today).endOf(unit).format("yyyy-MM-DD")
	return { dateFrom, dateTo }
}

export function getStartEndFinancialYear(unitsToAdd = 0) {
	const today = moment()
	const currentMonth = today.format("M")

	let monthsUntilNextJuly = 7 - currentMonth
	if (monthsUntilNextJuly <= 0) monthsUntilNextJuly += 12

	const dateFrom = moment(today)
		.add(monthsUntilNextJuly, "month")
		.set("date", 1)
		.subtract(1 - unitsToAdd, "years")
		.format("yyyy-MM-DD")
	const dateTo = moment(dateFrom).subtract(1, "days").add(1, "years").format("yyyy-MM-DD")
	return { dateFrom, dateTo }
}

export function numberWithCommas(str) {
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatDate(date, format = "YYYY-MM-DD") {
	return moment(date).format(format)
}

export function getTimeStamp() {
	return moment().format("YYYY_MM_DD")
}

export const getCertificateDownloadUrl = (id) => {
	return `${BACKEND_BASE_URL}${RETIREMENTS_CERTIFICATES_ENDPOINT}/${id}${API_VERSION_PARAM}`
}

export function downloadFile(access_token, url, fileName) {
	let config = {
		responseType: "blob",
		headers: {
			Authorization: `Bearer ${access_token}`,
		},
	}
	axios
		.get(url, config)
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", fileName) //or any other extension
			document.body.appendChild(link)
			link.click()
		})
		.catch(function (error) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				const err = error.response
				ErrorHandling(err.status, [err.data, err.status, err.headers])
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				ErrorHandling("Request failed", error.request)
			} else {
				// Something happened in setting up the request that triggered an Error
				ErrorHandling("Bad Request", error.message)
			}
			ErrorHandling("Something went wrong", error.config)
		})
}

export const isResponsesLoaded = (res = []) => (res.filter((r) => r.loaded && !r.error).length === res.length ? true : false)

export const deepEqual = (x, y) => {
	if (x === y) {
		return true
	} else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
		if (Object.keys(x).length != Object.keys(y).length) return false

		for (let prop in x) {
			if (Object.prototype.hasOwnProperty.call(y, prop)) {
				if (!deepEqual(x[prop], y[prop])) return false
			} else return false
		}

		return true
	} else return false
}

export const numberToPositionalNotation = (number, decimals, shorten = false) => {
	if (!number || number === 0) return "0"
	let denominator = 1
	let size = ""
	if(shorten){
		if (number >= 1000000) {
			denominator = 1000000
			size = "M"
		} else if (number >= 1000) {
			denominator = 1000
			size = "K"
		}
	}
	return `${parseFloat((number / denominator).toFixed(decimals))}${size}`
}

export const getIntervalBetween = (dateFrom, dateTo, interval = "month", format = "MMM YY") => {
	const between = []
	const startDate = moment(dateFrom)
	const endDate = moment(dateTo)

	if (startDate <= endDate) {
		let date = startDate

		while (date < endDate.endOf(interval)) {
			between.push(date.format(format))
			date.add(1, interval)
		}
	}
	return between
}

export const getThisLastDateRange = (filterData) => {
	let data = {}

	// Range from filter atom
	const { daysBetween, selectionString, dateFrom, dateTo } = filterData
	data.this = { dateFrom, dateTo }

	// Previous interval based on above
	if (selectionString) {
		const lastDataFunction = dateRangeRows.find((x) => x.title === selectionString)
		const lastData = lastDataFunction ? lastDataFunction.lastRange : getStartEndUnit("year", -1)
		data.last = { dateFrom: lastData.dateFrom, dateTo: lastData.dateTo }
	} else {
		const newStartDate = moment(dateFrom).subtract(daysBetween, "days")
		const newEndDate = moment(dateFrom).subtract(1, "day")
		data.last = { dateFrom: newStartDate.format("YYYY-MM-DD"), dateTo: newEndDate.format("YYYY-MM-DD") }
	}

	return data
}

export const objectOfBoolsToCSV = (obj) => {
	let strArray = []

	for (const key of Object.keys(obj)) {
		if (obj[key]) strArray.push(key.toLocaleLowerCase())
	}

	return strArray.join(",")
}

export const trimArrayOfKeyValueObjects = (data, maxLength = 8) => {
	const sortFunction = (a, b) => (a.value > b.value ? -1 : 1)

	const sortedData = [...data.sort(sortFunction)]
	let finalArray = [...sortedData]

	// >1 ensures that we arent trimming for the sake of putting one element under 'other'
	if (sortedData.length - maxLength > 1) {
		const initialData = sortedData.slice(0, maxLength)
		const poppedData = sortedData.slice(maxLength - sortedData.length)

		let otherObj = { key: "Other", value: 0 }
		poppedData.forEach((x) => (otherObj.value += x.value))
		finalArray = [...initialData, otherObj]
	}

	return finalArray
}

export function prettifyCostCentre(costCentreArray, separator = " > ") {
	if(costCentreArray === undefined) return
	return costCentreArray.map((x) => x.name ?? x.code).join(separator)
}
