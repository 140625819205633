import { getTimeStamp } from "../util/util"
import { configureSeries, formatSeries, formatSeries2 } from "./SeriesHelper"
import { palette } from "../theme"

export const LINE_CHART = "spline"
export const BAR_CHART = "column"
export const PIE_CHART = "pie"

const getChartOptions = () => ({
	chart: {
		type: "column",
		options3d: {
			enabled: true,
			alpha: 0,
			beta: 10,
			viewDistance: 90,
			depth: 70,
		},
	},
	credits: {
		enabled: true,
		text: "ourbluehalo.com",
		href: "https://ourbluehalo.com",
	},
	colors: [
		palette.charts.colorScale[0], // skyBlue, // BlueHalo Sky Blue
		palette.charts.colorScale[1], // electricBlue, // Electric Blue
		palette.charts.colorScale[2], // midBlue, // Mid Blue
		palette.charts.colorScale[3], // skyBlue.tertiary.main, // BlueHalo Deep Space
		palette.charts.colorScale[4], // leafGreen, // Brand Green
		// -----------------------------------
		palette.charts.colorScale[5], // skyBlue.tertiary.main, // BlueHalo Deep Space
		palette.charts.colorScale[6], // skyBlue, // BlueHalo Sky Blue
	],
	title: {
		text: "Offset Emissions",
		align: "left",
		margin: 20,
		style: {
			fontSize: "18px",
			fontWeight: "300",
			color: palette.text.primary,
		},
	},

	subtitle: {
		text: "Data source: BlueHalo",
		align: "left",
		y: 30,
		style: {
			fontSize: "13px",
			fontWeight: "400",
			color: palette.text.secondary,
		},
	},

	exporting: {
		allowHTML: true,
		fallbackToExportServer: false,
		enabled: true,
		sourceHeight: 500,
		sourceWidth: 1000,
		chartOptions: {
			chart: {
				style: {
					fontFamily: "Roboto Regular",
				},
			},
		},
		buttons: {
			contextButton: {
				menuItems: [
					"viewFullscreen",
					"printChart",
					"separator",
					"downloadPNG",
					"downloadJPEG",
					//"downloadPDF",
					"downloadSVG",
				],
			},
		},
	},

	plotOptions: {
		column: {
			borderWidth: 0,
			stacking: "normal",
			groupPadding: 0.2,
			depth: 80,
		},
		pie: {
			size: "65%", // Outer Size
			animation: {
				defer: 500,
				duration: 750,
			},
			startAngle: 0,
			innerSize: 100, // Inner sizer
			depth: 45,
			borderWidth: 0,
		},
		series: {},
	},

	xAxis: {
		gridLineWidth: 0,
		categories: [],
		title: {
			//text: 'Quarters by Year',
			skew3d: true,
		},
		labels: {
			skew3d: true,
			style: {
				fontSize: "13px",
				fontWeight: "bold",
				color: palette.text.secondary,
			},
		},
	},

	yAxis: {
		gridLineColor: palette.charts.skyBlue,
		gridLineWidth: 0.5,
		allowDecimals: false,
		//min: 0,
		title: {
			text: "Tonnes CO2e",
			style: {
				fontSize: "13px",
				fontWeight: "700",
				color: palette.text.secondary,
			},
		},
		skew3d: false,
		labels: {
			//format: '{value:.1f}',
			skew3d: true,
			style: {
				fontSize: "13px",
				fontWeight: "bold",
				color: palette.text.secondary,
			},
		},

		stackLabels: {
			enabled: true,
			//skew3d: true,
			align: "center",
			style: {
				fontWeight: "normal",
				fontSize: "16px",
				color: palette.text.secondary,
				textOutline: palette.white.main,
			},
			formatter: function () {
				return this.total ? Math.round(this.total) : undefined
			},
		},
	},

	tooltip: {
		headerFormat: '<span style="color:{series.color}">\u25CF</span><b> {series.name}</b><br>',
		pointFormat: "Generated <b>{point.y:.3f}</b> tonnes from the total of <b>{point.stackTotal:,.3f}</b><br> tonnes of CO2e across the whole company.",
	},

	legend: {
		align: "center",
		floating: false,
		borderWidth: 0,
		shadow: false,
		itemStyle: {
			fontSize: "13px",
			fontWeight: "normal",
			color: palette.text.secondary,
			textDecoration: "none",
		},
		itemHoverStyle: {
			fontWeight: "650",
			color: palette.text.primary,
			textDecoration: "underline",
		},
		itemHiddenStyle: {
			color: "#B1B1B1",
		},
	},

	series: [],
})

export const setChartSeries = (series, options) => {
	options.series = series ? series : []
}

export const setChartLables = (labels, options) => {
	options.xAxis.categories = labels ? labels : options.xAxis.categories
}

export function formatExportChartName(name) {
	const cleanName = name.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase()
	return `${cleanName}_${getTimeStamp()}`
}

const setChartOptions = (settings, options) => {
	if (!settings) return

	const { title, labels, yTitle, type, colors, seriesOptions, tooltip, xAxis, column, chart, exporting } = settings

	options.chart = chart ? { ...options.chart, ...chart } : options.chart
	options.chart.type = type ? type : options.chart.type
	options.title.text = title ? title : options.title.text
	options.title.fontWeight = 300
	options.title.color = "#374151"
	options.xAxis.categories = labels ? labels : options.xAxis.categories
	options.yAxis.title.text = yTitle ? yTitle : options.yAxis.title.text
	options.colors = colors ? colors : options.colors
	options.tooltip = tooltip ? { ...options.tooltip, ...tooltip } : options.tooltip
	options.exporting.filename = title ? formatExportChartName(title) : "chart"
	options.plotOptions.series = seriesOptions ? seriesOptions : options.plotOptions.series
	options.plotOptions.column = column ? { ...options.plotOptions.column, ...column } : options.plotOptions.column
	options.exporting = exporting ? { ...options.exporting, ...exporting } : options.exporting
	options.xAxis = xAxis ? xAxis : options.xAxis

	return options
}

function setSeriesNameIfOnlyOne(series = [], newName = "Offsets", ignore = ["net position", "retirements"]) {
	const filteredSeries = series.filter((s) => !ignore.includes(s.name.toLowerCase()))

	if (filteredSeries.length === 1) {
		series[0].name = newName
	}
	return series
}

function processChart(dataSet, chartSettings) {
	const charOptions = getChartOptions()
	setChartOptions(chartSettings, charOptions)
	const isDrillDown = chartSettings?.drilldown

	const labels = dataSet?.labels
	const data = setSeriesNameIfOnlyOne(dataSet?.data)
	const seriesConfig = chartSettings?.config ? chartSettings?.config : []
	let series = isDrillDown ? formatSeries2(data, labels) : formatSeries(data)

	if (seriesConfig.length > 0) {
		configureSeries(series, seriesConfig)
	}

	charOptions["series"] = isDrillDown ? [{ data: series }] : series
	setChartLables(labels, charOptions)

	return charOptions
}

export { setChartOptions, getChartOptions, processChart }
