import Carousel from "../Carousel"
import LoadingWrapper from "../Loading/LoadingWrapper"
import SDGProjectCard from "./SDGProjectCard"
import SkeletonProject from "./SkeletonProject"

import CleanPowerImg from "../../assets/banners/projects/cleanPower.jpeg"
import CookStoveImg from "../../assets/banners/projects/cookstoves.jpeg"
import CoolBurnImg from "../../assets/banners/projects/coolBurning.jpeg"
import RainforestImg from "../../assets/banners/projects/rainforest.jpeg"
import TheBushImg from "../../assets/banners/projects/theBush.jpeg"
import TheForestImg from "../../assets/banners/projects/nzForest.jpg"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"
import "./index.scss"

const COOL_BURNING = "COOL_BURNING"
const CLEAN_POWER = "CLEAN_POWER"
const RAINFORESTS = "RAINFORESTS"
const COOKSTOVES = "COOKSTOVES"
const BUSH = "BUSH"
const FORESTS = "FORESTS"
const RENEWABLE = "Renewable_Energy"
const GENERAL = "General_Energy"
const FOREST = "Forest_Energy"
//const availableProjects = [COOL_BURNING, CLEAN_POWER, RAINFORESTS, COOKSTOVES, BUSH, FORESTS]
const availableProjects = [RENEWABLE, GENERAL, FOREST]

const Project = ({ project, amount }) => {
	switch (project) {
		case COOL_BURNING:
			return (
				<SDGProjectCard
					amount={10}
					img={CoolBurnImg}
					title="Cool Burning"
					type="Fire Management"
					location="Northern Australia"
					subtitle="Reinstating traditional burning practices has demonstrated a significant reduction in carbon emissions along with highly valued social, cultural, environmental and economic benefits for Indigenous landowners."
					keyImpacts={["Local employment", "Community development", "Biodiversity protection", "Ancient culture site protection"]}
					body={
						<>
							<p>Technology : Fire</p>
							<p>"The Arnhem Land Fire Abatement (ALFA) is an Aboriginal owned, not-for-profit carbon farming business that supports Aboriginal Traditional Owners and rangers to utilise
							customary fire skills in tandem with contemporary technology, to scale fire management in areas prone to devastating wildfires that damage the landscape, including rock art
							galleries, cultural sites and biodiversity.</p>
						</>
					}
				/>
			)

		//case CLEAN_POWER:
		case RENEWABLE:
			return (
				<SDGProjectCard
					amount={10}
					img={CleanPowerImg}
					//title="Clean Power"
					title="Renewable Energy"
					type="Renewable Energy"
					location="Asia"
					subtitle={"Diversifying Asia's electricity production reduces emissions and inhibits local air pollutants associated with fossil fuels."}
					keyImpacts={["Emissions prevention", "Energy security", "Local employment", "Community health & education"]}
					body={
						<>
							<p>Technologies : "BioEnergy", "Geothermal", "Hydro", "Landfill Gas", "Solar", "Wind"</p>
							<p className="project-content">
								Across Asia, wind and solar farms introduce clean energy to the grid which would otherwise be generated by coal-fired power stations. Renewable energy is clean in two
								ways. First, it produces no emissions and removes the by-product of local air pollutants associated with fossil fuels. Second, the available electricity in the regions
								is improved, reducing the occurrence of blackouts across the area.
							</p>
							<p>
								These projects support national energy security, strengthen rural electrification coverage, and have enabled the construction of new roads in regional areas, improving
								accessibility for locals.
							</p>
						</>
					}
				/>
			)

		case COOKSTOVES:
			return (
				<SDGProjectCard
					amount={amount}
					img={CookStoveImg}
					title="Cookstoves"
					type="Efficient Cookstoves"
					location="Africa, Asia, South America"
					subtitle={"Introducing cleaner, more efficient stoves that significantly reduce smoke-related respiratory and heart disease for women and children."}
					keyImpacts={["Emissions prevention", "Improved health", "Local employment", "Female empowerment"]}
					body={
						<>
							<p>Technology : REDD</p>
							<p className="project-content">
								Numerous rural populations across Africa, Asia and South America cook on inefficient, traditional three-stone fires, often located inside poorly ventilated kitchens.
								This causes severe household air pollution, chronic respiratory, heart and eye disease, and imposes a health burden on women and children who are responsible for
								preparing meals.
							</p>
							<p>
								These offset projects build clean, efficient stoves that significantly improve indoor air quality and reduce health risks and burns. These stoves also reduce the amount
								of time women and children spend gathering firewood each week, allowing time for other activities such as going to school.
							</p>
						</>
					}
				/>
			)

		//case RAINFORESTS:
		case GENERAL:
			return (
				<SDGProjectCard
					amount={10}
					img={RainforestImg}
					//title="Rainforests"
					title="General Energy"
					type="Native Forest Conservation"
					location="South America, Oceania, Africa"
					subtitle={"Securing vital habitat for endangered rainforest species including the jaguar, bird of paradise, and southern crowned pigeon."}
					keyImpacts={["Emissions prevention", "Wildlife protection", "Habitat conservation", "Soil salinity & erosion prevention", "Community empowerment and sustainable agriculture"]}
					body={
						<>
							<p>Technology : "Electricity", "Fire", "General Energy", "Shipping", "TBA"</p>
							<p className="project-content">
								Projects across South America, Oceania and Africa protect millions of hectares of native forests from being cleared that supports wildlife habitat and local
								communities. This in turn, prevents millions of tonnes of greenhouse gas emissions from being released each year. Protecting the forests secures the carbon stored
								within the organic matter.
							</p>
							<p>
								These projects diversify landholder income, support sustainable agroforestry including cocoa and coffee production, reduces emissions, and secures vital habitat for
								millions of endemic and endangered rainforest species of animals and plants.
							</p>
						</>
					}
				/>
			)

		case BUSH:
			return (
				<SDGProjectCard
					amount={10}
					img={TheBushImg}
					title="The Bush"
					type="Native Forest Regeneration"
					location="New South Wales, Queensland"
					subtitle={"Working with landholders to regenerate and protect native vegetation by establishing vital habitats and food sources for native wildlife."}
					keyImpacts={["Emissions reduction", "Wildlife protection", "Habitat conservation", "Improved soil health"]}
					body={
						<>
							<p>Technology : Avoided Deforestation</p>
							<p>
								Widespread land clearing in New South Wales and Queensland has significantly impacted local ecosystems. The degradation and loss of plant species threatens the food and
								habitat on which other native species rely, allows weeds and invasive animals to spread, and affects greenhouse gas emissions leading to soil erosion and salinity.
							</p>
							<p>
								These projects work with landholders to regenerate and protect native vegetation, provide important habitats and nutrients for native wildlife, and inhibits emissions
								production caused by clearing. By establishing wildlife corridors on previously cleared land, animals can move across the landscape accessing food and a chance to boost
								populations.
							</p>
						</>
					}
				/>
			)

		//case FORESTS:
		case FOREST:
			return (
				<SDGProjectCard
					amount={10}
					img={TheForestImg}
					//title="Forests"
					title="Forest Energy"
					type="Native Forest Regeneration"
					location="New Zealand"
					subtitle={"Forest for the trees"}
					keyImpacts={["Climate action", "Life on land"]}
					body={
						<>
							<p>Technology : "Avoided Deforestation", "Forestry", "NZU_FE", "REDD", "Reforestation", "Regen"</p>
							<p className="project-content">
								Located across New Zealand, these projects establish forests to capture and store carbon. Protecting forests also reduces erosion, helps to clean waterways, provides
								sustainable income for local communities and secures precious habitat and food sources for native wildlife. Establishing forests to connect existing areas of vegetation
								also creates green corridors, allowing animals to move across the landscape.
							</p>
							<p>
								Working with landholders, the projects have introduced a diversified income stream for landholders, enabling their properties to generate income from protecting the
								carbon stored within the trees.
							</p>
						</>
					}
				/>
			)

		default:
			break
	}
}

const SDGProjectCarousel = () => {
	const body = setQueryParameters("POST")
	const { loaded, data, error } = useQueryEndpoint(RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT, body)

	let projectList = []
	if (loaded && !error) {
		if(data?.data?.listCount > 0){
			/* this is a temporary code change to see what shakes out of uat */
			//const projectNames = data?.data?.projects.map((i) => i.projectCode)
			const projectNames = data?.data?.retirements.map((i) => i.projectType)
			const mappedProjects = []
			projectNames.map((project) => {
				switch(project){
					case "BioEnergy" :
					case "Wind" :
					case "Solar" :
					case "Geothermal" :
					case "Hydro" :
					case "Landfill Gas" :
						if(!mappedProjects.includes(RENEWABLE)) mappedProjects.push(RENEWABLE)
						break;
					case "Electricity" :
					case "Fire" :
					case "General Energy" :
					case "Shipping" :
					case "TBA" :
						if(!mappedProjects.includes(GENERAL)) mappedProjects.push(GENERAL)
						break;
					case "Avoided Deforestation" :
					case "Forestry" :
					case "NZU_FE" :
					case "REDD" :
					case "Reforestation" :
					case "Regen" :
						if(!mappedProjects.includes(FOREST)) mappedProjects.push(FOREST)
						break;
				}
			})
			/*
			projectNames.map((project) => {
				switch(project){
					case "BioEnergy" :
					case "Wind" :
					case "Solar" :
						if(!mappedProjects.includes(CLEAN_POWER)) mappedProjects.push(CLEAN_POWER)
						break;
					case "Avoided Deforestation" :
						mappedProjects.push(BUSH)
						break;
					case "Regen" :
						mappedProjects.push(RAINFORESTS)
						break;
					case "Fire" :
						mappedProjects.push(COOL_BURNING)
						break;
					case "REDD" :
						mappedProjects.push(COOKSTOVES)
						break;
					case "NZU_FE" :
						mappedProjects.push(FORESTS)
						break;
				}
			})
				*/
			//const filteredProjectNames = projectNames.filter((project) => availableProjects.includes(project))
			const filteredProjectNames = mappedProjects.filter((project) => availableProjects.includes(project))
			projectList = filteredProjectNames.map((v, i) => <Project project={v} key={i} />)
		}
	}

	return (
		<LoadingWrapper
			error={error}
			loaded={loaded}
			loadingComponent={
				<Carousel>
					<SkeletonProject key={1} />
					<SkeletonProject key={2} />
					<SkeletonProject key={3} />
				</Carousel>
			}
		>
			<Carousel>{projectList}</Carousel>
		</LoadingWrapper>
	)
}
export default SDGProjectCarousel
