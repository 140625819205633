export const EMISSIONS_CH1 = {
	data: {
		values: [
			{
				date: "Aug 2021",
				partnerCode: "WebJet",
				value: 12,
			},
			{
				date: "Sep 2021",
				partnerCode: "WebJet",
				value: 13,
			},
			{
				date: "Nov 2021",
				partnerCode: "WebJet",
				value: 14,
			},
			{
				date: "Aug 2021",
				partnerCode: "Quantas",
				value: 22,
			},
			{
				date: "Sep 2021",
				partnerCode: "Quantas",
				value: 33,
			},
			{
				date: "Nov 2021",
				partnerCode: "Quantas",
				value: 44,
			},
		],
	},
}

export const PG1CH3_Offsets_By_Initiative = {
	labels: ["Jetstar", "Home/car offset", "Dollar for Dollar", "Freight", "Tick the box"],
	aggregationType: "travelClass",
	data: [
		{
			name: "Project Impact",
			rows: [5988, 8875, 12551, 36142, 45574],
		},
	],
}

export const PG1CH4_Emissions_By_TravelClass = {
	labels: ["First", "Business", "Economy", "Premium Economy"],
	aggregationType: "travelClass",
	data: [
		{
			name: "Emissions Travel Class",
			rows: [45, 30, 12, 8],
			data: [
				{
					name: "WebJet",
					rows: [45, 30, 12, 8],
				},
				{
					name: "QUANTAS",
					rows: [45, 30, 12, 8],
				},
			],
		},
	],
}

export const PG1CH5_Emissions_Offset_Volumes_Month = {
	labels: ["Sep 2020", "Oct 2020", "Nov 2020", "Dec 2020", "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021", "Sep 2021"],
	aggregationType: "country",
	data: [
		{
			name: "Offset home / car",
			rows: [697, 242, 685, 224, 589, 88, 1687, 611, 574, 248, 625, 211, 358],
		},
		{
			name: "Match dollar for dollar",
			rows: [1995, 542, 1685, 2224, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Tick the box",
			rows: [1995, 542, 1685, 2224, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Freight",
			rows: [1995, 542, 1685, 2224, 1589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Offset Retirements",
			rows: [6992, null, null, 14504, null, null, 21156, null, null, 26732, null, null, 7521],
		},
	],
}

// =========== PAGE 2 =========================

export const PG2CH6_Net_Position = {
	labels: ["Sep 2020", "Oct 2020", "Nov 2020", "Dec 2020", "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021", "Sep 2021"],
	aggregationType: "Month",
	data: [
		{
			name: "Net position",
			rows: [6682, -310, 1558, 7298, 14194, 1690, 13046, 14342, 15638, 5, 13398, 17242, 10953],
		},
		{
			name: "Offset home / car",
			rows: [697, 242, 685, 224, 589, 88, 1687, 611, 574, 248, 625, 211, 358],
		},
		{
			name: "Match dollar for dollar",
			rows: [1995, 542, 1685, 2224, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Tick the box",
			rows: [1995, 542, 1685, 2224, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Freight",
			rows: [1995, 542, 1685, 2224, 1589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Offsets Retired",
			rows: [-6992, null, null, -12504, null, null, -17156, null, null, -15732, null, null, -7521],
		},
	],
}

export const PG2CH7_Retired_Offsets_by_Country = {
	labels: ["India", "Australia", "NZ", "Asia"],
	aggregationType: "country",
	data: [
		{
			name: "Project Impact",
			rows: [45, 10, 10, 45],
		},
	],
}

export const PG2CH8_Retired_Offset_by_Climate_Technology = {
	labels: ["Clean Energy", "Forest Regen", "Cool Burning"],
	aggregationType: "country",
	data: [
		{
			name: "Retired Offsets",
			rows: [20142, 14574, 5241],
		},
	],
}

export const HOMECH1_Offset_Emissions = {
	labels: ["Sep 2020", "Oct 2020", "Nov 2020", "Dec 2020", "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021"],
	data: [
		{
			name: "Webjet New Zealand",
			rows: [697, 242, 685, 224, 589, 88, 1687, 611, 574, 248, 211, 358],
		},
		{
			name: "Webjet Australia",
			rows: [1995, 542, 1685, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
		{
			name: "Webjet Burkina Faso",
			rows: [542, 1685, 2224, 4589, 988, 1687, 2611, 3574, 2248, 3625, 1211, 958],
		},
	],
}

const SDG_COLORS = ["E5243B", "DDA83A", "4C9F38", "C5192D", "FF3A21", "26BDE2", "FCC30B", "A21942", "FD6925", "DD1367", "FD9D24", "BF8B2E", "3F7E44", "0A97D9", "56C02B", "00689D", "19486A"]
export const UNC_MOCK_DATA = {
	data: {
		sdgOffsets: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17"].map((v, i) => ({
			sdgId: i,
			sdgColour: SDG_COLORS[i],
			sdgUrl: `https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-${v}.png`,
			totalOffsets: ["09", "10"].includes(v) ? 0 : i + 1,
		})),
	},
}

export const UNC_MOCK_DATA_2 = {
	data: {
		records: [
			{
				id: 1,
				sdgColour: "#E5243B",
				sdgName: "Goal1",
				amount: 100,

				sdgUrl: "https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-01.png",
			},
			{
				id: 3,
				sdgColour: "#DDA83A",
				sdgName: "Goal2",
				amount: 33,
				sdgUrl: "https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-02.png",
			},
			{
				id: 2,
				sdgColour: "#FD9D24",
				sdgName: "Goal11",
				amount: 22,
				sdgUrl: "https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-03.png",
			},
			{
				id: 3,
				sdgColour: "#4C9F38",
				sdgName: "Goal13",
				amount: 33,
				sdgUrl: "https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-04.png",
			},
			{
				id: 3,
				sdgColour: "#C5192D",
				sdgName: "Goal3",
				amount: 33,
				sdgUrl: "https://bhcrpd416965799036.blob.core.windows.net/sdgimages/E-WEB-Goal-05.png",
			},
		],
	},
}

export const COST_CENTRE_DATA = [
	{
		hashKey: "1",
		value: {
			name: "Hawthorn Campus",
			"Apr 2023": 20,
			"May 2023": 20,
		},
		childrenValues: [
			{
				hashKey: "2",
				value: {
					name: "Health Science",
					"Apr 2023": 10,
					"May 2023": 10,
				},
				childrenValues: [],
			},
			{
				hashKey: "3",
				value: {
					name: "Engineering",
					"Apr 2023": 10,
					"May 2023": 10,
				},
				childrenValues: [
					{
						hashKey: "4",
						value: {
							name: "IT",
							"Apr 2023": 5,
							"May 2023": 5,
						},
						childrenValues: [],
					},
					{
						hashKey: "5",
						value: {
							name: "Civil",
							"Apr 2023": 5,
							"May 2023": 5,
						},
						childrenValues: [],
					},
				],
			},
		],
	},
]

export const COST_CENTRE_DATA_MODE = [
	{
		date: "Apr 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 20 ,
		costCentres: [{ name: "Hawthorn Campus", code: "HAW" }],
	},
	{
		date: "Apr 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 10,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Health Science", code: "HS" },
		],
	},
	{
		date: "Apr 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 10,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
		],
	},
	{
		date: "Apr 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 5,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
			{ name: "IT", code: "IT" },
		],
	},
	{
		date: "Apr 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 5,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
			{ name: "Civil", code: "CIV" },
		],
	},
	{
		date: "May 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 20,
		costCentres: [{ name: "Hawthorn Campus", code: "HAW" }],
	},
	{
		date: "May 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 10,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Health Science", code: "HS" },
		],
	},
	{
		date: "May 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 10,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
		],
	},
	{
		date: "May 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 5,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
			{ name: "IT", code: "IT" },
		],
	},
	{
		date: "May 2023",
		partnerCode: "METLIFE-AUS",
		emissions: 5,
		costCentres: [
			{ name: "Hawthorn Campus", code: "HAW" },
			{ name: "Engineering", code: "ENG" },
			{ name: "Civil", code: "CIV" },
		],
	},
]

export const COST_CENTRE_DATA_RESPONSE = {
	air: COST_CENTRE_DATA_MODE,
	accommodation: COST_CENTRE_DATA_MODE,
	road: COST_CENTRE_DATA_MODE,
	sea: COST_CENTRE_DATA_MODE,
	rail: COST_CENTRE_DATA_MODE,
}

export const COST_CENTRE_HEIRARCHICAL_DATA = [
	{
		hashKey: "1",
		costCentre: { name: "Hawthorn Campus", code: "HAW" },
		value: {
			name: "Hawthorn Campus",
			"Apr 2023": 100,
			"May 2023": 100,
			total: 200,
		},
		childrenValues: [
			{
				hashKey: "2",
				costCentre: { name: "Health Science", code: "HS" },
				value: {
					name: "Health Science",
					"Apr 2023": 50,
					"May 2023": 50,
					total: 100,
				},
				childrenValues: [],
			},
			{
				hashKey: "3",
				costCentre: { name: "Engineering", code: "ENG" },
				value: {
					name: "Engineering",
					"Apr 2023": 50,
					"May 2023": 50,
					total: 100,
				},
				childrenValues: [
					{
						hashKey: "4",
						costCentre: { name: "IT", code: "IT" },
						value: {
							name: "IT",
							"Apr 2023": 25,
							"May 2023": 25,
							total: 50,
						},
						childrenValues: [],
					},
					{
						hashKey: "5",
						costCentre: { name: "Civil", code: "CIV" },
						value: {
							name: "Civil",
							"Apr 2023": 25,
							"May 2023": 25,
							total: 50,
						},
						childrenValues: [],
					},
				],
			},
		],
	},
]

export const EMISSIONS_LOG_ACCOM_ITEM = {
	id: "c719382d-3ea1-4625-bfbb-0447d5cc35af",
	mode: "accommodation",
	category: "passenger",
	emissions: 0.001,
	transactionDate: "2022-02-10T09:37:00.000Z",
	partnerCode: "WEBJET",
	metadata: {
		hotelChain: "Accor",
		hotelName: "TEST Peppers",
		roomNights: 4,
		numberRooms: 3,
		country: "AUS",
		region: "Gold Coast",
		postcode: "7899",
		starRating: "4 star",
		activityDate: "2022-02-10T09:37:00.000Z",
		costCentre: [
			{
				name: "Digital",
				code: "DIGI",
			},
			{
				name: "Product",
				code: "PRD",
			},
			{
				name: "Carbon Expert",
				code: "CBE",
			},
		],
	},
}

export const EMISSIONS_LOG_ROAD_ITEM_TRAVEL = {
	id: "2a6b5789-6b32-49fc-a2ac-240f6b7ba70e",
	mode: "road",
	category: "passenger",
	emissions: 6.90268,
	transactionDate: "2022-07-14T09:21:48.575Z",
	partnerCode: "IAG",
	metadata: {
		distance: 20000,
		vehicleGroup: "Car",
		usageType: "Business",
		countryCode: "AUS",
		vehicleYear: 2015,
		vehicleMake: "FORD",
		vehicleModel: "Ranger",
		passengerDistanceTierId: "464c0bda-c80d-427b-b1b3-47c375c8135e",
		sippsAcrissMapId: "b78995ca-c358-4c17-9983-2a9f3156bb3b",
		tripType: "Personal",
		costCentre: [
			{
				name: "Unknown",
				code: "NULL",
			},
		],
	},
}

export const EMISSIONS_LOG_ROAD_ITEM_FREIGHT = {
	id: "2a6b5789-6b32-49fc-a2ac-240f6b7ba70e",
	mode: "road",
	category: "freight",
	emissions: 6.90268,
	transactionDate: "2022-07-14T09:21:48.575Z",
	partnerCode: "IAG",
	metadata: {
		fromCoord: -10,
		toCoord: 35,
		freightWeight: 0.3,
		freightUom: "kg",
		vehicleType: "Truck",
		vehicleSize: "large",
		loadSize: "huge",
		fuel: "Diesel",
		costCentre: [
			{
				name: "Unknown",
				code: "NULL",
			},
		],
	},
}

export const EMISSIONS_LOG_AIR_ITEM = {
	id: "bcb98b7c-2435-46be-8699-b8125cdcea22",
	mode: "air",
	category: "passenger",
	emissions: 6.475976,
	transactionDate: "2023-01-01T23:28:42.148Z",
	partnerCode: "WEBJET",
	metadata: {
		flightFrom: "AKL       ",
		flightTo: "MEL       ",
		passengers: 4,
		travelClass: "Economy",
		aircraftType: "73H",
		airlineDesignator: "EK    ",
		operatingAirlineDesignator: "QF    ",
		destinationAirportName: "Melbourne",
		destinationCountry: "Australia",
		sourceAirportName: "Auckland",
		sourceCountry: "New Zealand",
		distance: 10583.04618,
		costCentre: [
			{
				name: "Unknown",
				code: "NULL",
			},
		],
	},
}

export const EMISSIONS_LOG_SEA_ITEM = {
	id: "2a6b5789-6b32-49fc-a2ac-240f6b7ba70e",
	mode: "rail",
	category: "freight",
	emissions: 6.90268,
	transactionDate: "2022-07-14T09:21:48.575Z",
	partnerCode: "IAG",
	metadata: {
		freightRunner: "Forwarder 1",
		freightUom: "kg",
		freightWeight: 0.2,
		sourcePortName: "Waurn Ponds",
		sourceCountryName: "Australia",
		destinationPortName: "Deer Park",
		destinationCountryName: "Australia",
		isInternational: true,
		distance: 100.0,
		shipName: "Test ship",
		shipMode: "fast",
		shipType: "cargo",
		shipSize: "big",
		imoCode: "KJSDNF",
		costCentre: [
			{
				name: "Unknown",
				code: "NULL",
			},
		],
	},
}

export const EMISSIONS_LOG_RAIL_ITEM = {
	id: "2a6b5789-6b32-49fc-a2ac-240f6b7ba70e",
	mode: "sea",
	category: "freight",
	emissions: 6.90268,
	transactionDate: "2022-07-14T09:21:48.575Z",
	partnerCode: "IAG",
	metadata: {
		serviceProvider: "Provider 1",
		railDesignator: "Designator 1",
		freightForwarder: "Forwarder 1",
		freightUom: "kg",
		freightWeight: 0.2,
		sourceStationName: "Waurn Ponds",
		sourceCountryName: "Australia",
		destinationStationName: "Deer Park",
		destinationCountryName: "Australia",
		isInternational: true,
		distance: 100.0,
		costCentre: [
			{
				name: "Unknown",
				code: "NULL",
			},
		],
	},
}

export const EMISSIONS_LOG_RESPONSE = {
	logs: [EMISSIONS_LOG_ACCOM_ITEM, EMISSIONS_LOG_AIR_ITEM, EMISSIONS_LOG_ROAD_ITEM_TRAVEL, EMISSIONS_LOG_RAIL_ITEM, EMISSIONS_LOG_ROAD_ITEM_FREIGHT, EMISSIONS_LOG_SEA_ITEM],
	pageSize: 6,
	pageNumber: 0,
	totalRows: 6,
}
