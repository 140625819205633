import { ACTIVITY_CHART_CONFIG, EMISSIONS_OVERVIEW_ENDPOINT, LATEST_API_VERSION } from "../../../util/Constants"
import { getAllEmissionsByKeyForDonut } from "../../../lib/convertions/EmissionsDataSet"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import { palette } from "../../../theme"
import { useModeList } from "../../../hooks/ModeHook"
import Donut from "../../ChartsVisX/ChartType/Donut/Donut"

const queryData = { method: "get", url: EMISSIONS_OVERVIEW_ENDPOINT, params: { interval: "month", "api-version": LATEST_API_VERSION } }

const colorsTransform = (colors, keys) => {
	return keys.map((x) => ACTIVITY_CHART_CONFIG[x.toLowerCase()].color)
}

const AllEmissionsByModeDonut = () => {
	const modes = useModeList()

	return (
		<VisxChartWrapper queryData={queryData} dataTransformFunction={(data) => getAllEmissionsByKeyForDonut(data.data, "emissions.total", modes)}>
			{({ transformedData, width }) => (
				<Donut
					{...{
						containerWidth: width,
						containerHeight: (6 * width) / 11,
						barColors: palette.charts.colorScale,
						title: "By travel mode",
						subTitle: null,
						unit: "tCO2e",
						data: transformedData,
						showTotals: true,
						colorsTransform,
					}}
				/>
			)}
		</VisxChartWrapper>
	)
}
export default AllEmissionsByModeDonut
