import { Typography, useTheme } from "@mui/material"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import LayoutItem from "../../layoutBuilder/LayoutItem"
import LayoutWrapper from "../../layoutBuilder/LayoutWrapper"
import { API_VERSION_PARAM, RETIREMENTS_CERTIFICATES_ENDPOINT } from "../../util/Constants"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"
import { formatDate, getCertificateDownloadUrl, getStartEndDate } from "../../util/util"
import AuthenticatedLink from "../AuthenticatedLink"
import CertificateGrid from "./CertificateGrid"
import SkeletonGrid from "./SkeletonGrid"
import { useAtomValue } from "jotai"
import { userAtom } from "../../atoms/user"
import { usePartnerAtomValue } from "../../hooks/PartnerHooks"

const columns = [
	{
		field: "dealRef",
		headerName: "Reference",
		width: 350,
		editable: false,
	},
	{
		field: "filename",
		headerName: "Filename",
		width: 350,
		editable: false,
		sortable: false,
	},
	{
		field: "dwUrl",
		headerName: "Report",
		describe: "download",
		width: 250,
		sortable: false,
		renderCell: (props) => {
			const url = getCertificateDownloadUrl(props.row.dwId)
			return (
				<AuthenticatedLink url={url} filename={props.row.filename}>
					Download certificate
				</AuthenticatedLink>
			)
		},
	},
]

const formatCertificateRows = (certificates = []) => {
	if (!certificates.length) return []

	return certificates.map((cert, index) => {
		return {
			id: index,
			dealRef: cert.dealReference,
			displayName: cert.displayName,
			retirementDate: cert?.retirementDate ? formatDate(cert?.retirementDate) : "",
			paymentDate: cert?.paymentDate ? formatDate(cert?.paymentDate) : "",
			dwId: cert.id,
			filename: cert.filename,
		}
	})
}

const NoRetirements = () => {
	return (
		<Typography variant="body1" textAlign="start" sx={{ mb: 2, p: 2 }}>
			You have no completed invoices. Invoices are usually finalised quarterly meaning you will see a retirement certificate here once that time window has elapsed.
		</Typography>
	)
}

const CertificateTable = () => {
	const user = useAtomValue(userAtom)
	const partnerVal = usePartnerAtomValue()

	const theme = useTheme()
	// return all the retirement certificates
	// TODO: change start date to something more reliable that 84 months ago
	const body = { ...getStartEndDate(84), partnerCodes: partnerVal.split(",") }

	const { data, error, loaded } = useQueryEndpoint(RETIREMENTS_CERTIFICATES_ENDPOINT + API_VERSION_PARAM, body)
	if (loaded && !error) {
		const cert = data?.data?.certificates || []
		const gridData = { columns: columns, rows: formatCertificateRows(cert) }

		return (
			<LayoutWrapper>
				<LayoutItem innerStyle={{ padding: theme.spacing(0, 2) }}>{cert.length === 0 ? <NoRetirements /> : <CertificateGrid gridData={gridData} pageSize={15} />}</LayoutItem>
			</LayoutWrapper>
		)
	}

	return <SkeletonGrid headers={columns.map((i) => i.headerName)} count={10} />
}
export default CertificateTable
