import { useTheme } from "@mui/system"
import React, { useState } from "react"
import { usePageTitle } from "../hooks/TitleHook"
import LayoutItem from "../layoutBuilder/LayoutItem"
import LayoutWrapper from "../layoutBuilder/LayoutWrapper"
import { RetirementsByProjectType } from "./controlTables/RetirementsByProjectTypeEndpoint"
import { RetirementsByCountryEndpoint } from "./controlTables/RetirementsByCountryEndpoint"
import { RetirementsCertificatesEndpoint } from "./controlTables/RetirementsCertificatesEndpoint"
import { DonutChartEndpoints } from "./controlTables/DonutChartEndpoints"
import { CustomerModeEndpoint } from "./controlTables/CustomerModeEndpoint"
import { DashboardKPIEndpoint } from "./controlTables/DashboardKPIEndpoint"
import { EmissionsOverviewEndpoint } from "./controlTables/EmissionsOverviewEndpoint"
import { EMISSIONS_AIRBYCLASS_ENDPOINT,
		EMISSIONS_ACCOMMBYSTAR_ENDPOINT,
		EMISSIONS_ACCOMMBYCOUNTRY_ENDPOINT,
		EMISSIONS_ROADBYRENTALDAYS_ENDPOINT,
		EMISSIONS_ROADBYACRISS_ENDPOINT, 
		LATEST_API_VERSION } from "../util/Constants"

const GroundControl = () => {
	usePageTitle("BlueHalo - Ground Control")
	const theme = useTheme()

	const layoutItemProps = {
		lg: 6,
		xl: 6,
		minHeight: 220,
		elevation: 0,
		innerStyle: { padding: 0 },
		outerStyle: { padding: 0, margin: theme.spacing(3) },
	}

	const initialState = {
		kpi: false,
		projects: false,
		retirements: false,
		emissions: false,
		sdg: false,
	}

	const [showHideState, setShowHideState] = useState(initialState)

	const showHide = (section) => {
		const value = !showHideState[section]
		setShowHideState((prevState) => ({ ...prevState, [section]: value }))
	}

	return (
		<LayoutWrapper className="p-2-theme">
			<LayoutWrapper>
				<LayoutItem>
					<CustomerModeEndpoint />
				</LayoutItem>
				<LayoutItem>
					<h2>Dashboard KPI endpoint</h2>
					<button onClick={() => showHide("kpi")}>{showHideState.kpi ? "Hide" : "Show"}</button>
					{showHideState.kpi && <DashboardKPIEndpoint />}
				</LayoutItem>
			</LayoutWrapper>
			<LayoutWrapper>
				<LayoutItem>
					<h2>Project endpoints</h2>
					<button onClick={() => showHide("projects")}>{showHideState.projects ? "Hide" : "Show"}</button>
					{showHideState.projects && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<RetirementsByProjectType />
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
				<LayoutItem>
					<h2>Retirement endpoints</h2>
					<button onClick={() => showHide("retirements")}>{showHideState.retirements ? "Hide" : "Show"}</button>
					{showHideState.retirements && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<RetirementsByProjectType />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<RetirementsByCountryEndpoint />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<RetirementsCertificatesEndpoint />
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
				<LayoutItem>
					<h2>Emissions endpoints</h2>
					<button onClick={() => showHide("emissions")}>{showHideState.emissions ? "Hide" : "Show"}</button>
					{showHideState.emissions && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<EmissionsOverviewEndpoint />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Air</h3>
								<h4>Group by Travel class</h4>
								<DonutChartEndpoints
									title={"By class"}
									modeOverride={["airEmissions"]}
									queryData={{ method: "get", url: EMISSIONS_AIRBYCLASS_ENDPOINT, params: { groupBy: "travelClass", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Road</h3>
								<h4>Group by Rental days</h4>
								<DonutChartEndpoints
									title={"Rental days"}
									modeOverride={["roadEmissions"]}
									queryData={{ method: "get", url: EMISSIONS_ROADBYRENTALDAYS_ENDPOINT, params: { groupBy: "rentalDays", "api-version": LATEST_API_VERSION } }}
								/>
								<h4>Group by ACRISS Code</h4>
								<DonutChartEndpoints
									title={"ACRISS"}
									modeOverride={["roadEmissions"]}
									queryData={{ method: "get", url: EMISSIONS_ROADBYACRISS_ENDPOINT, params: { groupBy: "acrissCategory", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Accommodation</h3>
								<h4>Group by Star rating</h4>
								<DonutChartEndpoints
									title={"By star rating"}
									modeOverride={["accommodationEmissions"]}
									queryData={{ method: "get", url: EMISSIONS_ACCOMMBYSTAR_ENDPOINT, params: { groupBy: "starRating", "api-version": LATEST_API_VERSION } }}
								/>
								<h4>Group by Country</h4>
								<DonutChartEndpoints
									title={"By country"}
									modeOverride={["accommodationEmissions"]}
									queryData={{ method: "get", url: EMISSIONS_ACCOMMBYCOUNTRY_ENDPOINT, params: { groupBy: "country", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
			</LayoutWrapper>
		</LayoutWrapper>
	)
}

export default GroundControl
