import React from "react"
import { BarStack } from "@visx/shape"
import { Group } from "@visx/group"
import { Grid } from "@visx/grid"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { palette } from "../../../../theme"
import StackedBarHeading from "./sub-components/StackedBarHeading"
import StackedBarTooltip from "./sub-components/StackedBarTooltip"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material"
import { useFilterAtomValue } from "../../../../hooks/FilterHooks"
import { getIntervalBetween } from "../../../../util/util"

// Data Format
// [{xValue: , yValue: [{key: , value: }]}]

const getX = (d) => d.xValue

// Maps array of {key: , value: }'s to {x, [key]: value} object and filters out keys that are true in the filter
const mapDataToObject = (data, filters = {}) => {
	const mappedData = data.map((d) => {
		const keyValMap = d.yValue.filter((val) => (val.key in filters ? filters[val.key] : true)).reduce((acc, curr) => ((acc[curr.key] = curr.value), acc), {})
		return { xValue: d.xValue, ...keyValMap }
	})
	return mappedData
}

export default function StackedBar({
	xLabel,
	yLabel,
	width,
	height,
	barColors = palette.charts.colorScale,
	data,
	showTotals,
	showGrid,
	numTicks = 4,
	title,
	subTitle,
	unit,
	customStackSortOrder = null,
	barColorsTransform = null,
}) {
	if (width < 10) return null
	const theme = useTheme()
	const filterVal = useFilterAtomValue()
	const margin = { top: 70, right: 20, bottom: xLabel ? 50 : 30, left: yLabel ? 65 : 40 }

	const [filters, setFilters] = React.useState({})
	const [mappedData, setMappedData] = React.useState([])

	const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip()
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true,
	})

	let segmentKeys = []

	const yTotals = data.reduce((accumulatedTotal, currentData) => {
		const totalYForBar = currentData.yValue.reduce((accumulatedSingleBar, yValue) => {
			accumulatedSingleBar += Number(yValue.value)
			if (!segmentKeys.includes(yValue.key)) segmentKeys.push(yValue.key)
			return accumulatedSingleBar
		}, 0)
		accumulatedTotal.push(totalYForBar)
		return accumulatedTotal
	}, [])

	const getXAxis = () => {
		if (data.length === 0) {
			return getIntervalBetween(filterVal.dateFrom, filterVal.dateTo)
		}
		return data.map(getX)
	}

	const getYAxis = () => {
		if (data.length === 0) {
			return [0, 10000]
		}
		return [0, Math.max(...yTotals)]
	}

	// Create Scale For Axis
	const xAxisScale = scaleBand({
		domain: getXAxis(),
		padding: 0.45,
	})
	const yAxisScale = scaleLinear({
		domain: getYAxis(),
		nice: true,
	})

	const domain = customStackSortOrder ? segmentKeys.sort(customStackSortOrder) : segmentKeys
	const colorScale = scaleOrdinal({
		domain,
		range: barColorsTransform ? barColorsTransform(barColors, domain) : barColors,
	})

	// On Clicks
	const filterData = (label) => {
		const key = label.datum
		const existingFilterForKey = key in filters

		if (existingFilterForKey) {
			filters[key] = !filters[key]
		} else {
			filters[key] = false
		}
		setFilters({ ...filters })
	}

	// bounds
	const finalWidth = width - margin.left - margin.right
	const finalHeight = height - margin.top - margin.bottom

	// Spread x-axis and y-axis across max-bounds
	xAxisScale.rangeRound([0, finalWidth])
	yAxisScale.range([finalHeight, 0])

	// On render
	React.useEffect(() => {
		setMappedData(mapDataToObject(data, filters))
	}, [filters, data])

	if (width < 10) return null

	return (
		<Box position="relative" {...{ width, height }}>
			<StackedBarHeading width={width} title={title} subTitle={subTitle} colorScale={colorScale} filters={filters} filterData={filterData} />
			<svg data-testid="graph-container" ref={containerRef} width={width} height={height}>
				{showGrid && (
					<Grid
						top={margin.top}
						left={margin.left}
						xScale={xAxisScale}
						yScale={yAxisScale}
						width={finalWidth}
						height={finalHeight}
						stroke="black"
						strokeOpacity={0.1}
						xOffset={xAxisScale.bandwidth() / 2}
					/>
				)}
				{data.length ? (
					<Group top={margin.top} left={margin.left}>
						<BarStack data={mappedData} keys={segmentKeys.filter((val) => (val in filters ? filters[val] : true))} x={getX} xScale={xAxisScale} yScale={yAxisScale} color={colorScale}>
							{(barStacks) => {
								let textPositionForTotals = {}

								return (
									<>
										{barStacks.map((barStack) =>
											barStack.bars.map((bar) => {
												// Get position for text representing bar stack total
												const total = bar.bar.data[bar.key]
												const exists = `${bar.x}` in textPositionForTotals

												if (!exists || (textPositionForTotals[bar.x].y >= bar.y && bar.height > 0)) {
													let newTotal = total
													if (exists) {
														newTotal = newTotal + textPositionForTotals[bar.x].total
													}

													textPositionForTotals[bar.x] = { y: bar.y, height: bar.height, width: bar.width, total: newTotal, xValue: bar.bar.data.xValue }
												} else {
													textPositionForTotals[bar.x].total = textPositionForTotals[bar.x].total + total
												}

												return (
													<rect
														key={`bar-stack-${barStack.index}-${bar.index}`}
														data-testid={`bar-stack`}
														data-xval={bar.bar.data.xValue}
														data-key={bar.key}
														data-value={bar.bar.data[bar.key]}
														x={bar.x}
														y={bar.y}
														height={bar.height}
														width={bar.width}
														fill={bar.color}
														onMouseLeave={() => {
															hideTooltip()
														}}
														onMouseMove={() => {
															showTooltip({
																tooltipData: bar,
																tooltipTop: textPositionForTotals[bar.x].y - 50,
																tooltipLeft: bar.x,
															})
														}}
													/>
												)
											})
										)}

										{showTotals &&
											Object.keys(textPositionForTotals).map((x) => (
												<text
													data-testid={"bar-stack-total"}
													data-xval={textPositionForTotals[x].xValue}
													dominantBaseline="middle"
													textAnchor="middle"
													fill={theme.palette.text.secondary}
													key={`bar-stack-text--${x}`}
													x={Number(x) + textPositionForTotals[x].width / 2}
													y={textPositionForTotals[x].y - 10}
													height={20}
													style={{ fontSize: "0.7rem" }}
												>
													{textPositionForTotals[x].total ? textPositionForTotals[x].total.toFixed(0) : ""}
												</text>
											))}
									</>
								)
							}}
						</BarStack>
					</Group>
				) : (
					<text dominantBaseline="middle" textAnchor="middle" fill={theme.palette.text.secondary} x={width / 2} y={height / 2} height={20} style={{ fontSize: "0.7rem" }}>
						No data provided to BlueHalo
					</text>
				)}
				<AxisBottom
					top={finalHeight + margin.top + 2}
					left={margin.left - 1}
					scale={xAxisScale}
					stroke={theme.palette.text.main}
					tickLabelProps={() => ({
						fill: theme.palette.text.secondary,
						fontSize: width > 800 ? "0.8rem" : "0.5rem",
						textAnchor: "middle",
					})}
					label={xLabel ? xLabel : null}
					hideAxisLine
					hideTicks
				/>
				<AxisLeft
					scale={yAxisScale}
					left={margin.left - 10}
					top={margin.top + 4}
					stroke={theme.palette.text.main}
					tickStroke={theme.palette.text.main}
					tickLabelProps={() => ({
						fill: theme.palette.text.secondary,
						fontSize: width > 800 ? "0.8rem" : "0.5rem",
						textAnchor: "middle",
					})}
					label={yLabel ? yLabel : false}
					labelProps={{
						fill: theme.palette.text.main,
						fontSize: width > 800 ? "0.8rem" : "0.5rem",
					}}
					labelOffset={35}
					numTicks={numTicks}
					hideAxisLine
					hideTicks
				/>
			</svg>

			{tooltipOpen && tooltipData && (
				<StackedBarTooltip colorScale={colorScale} TooltipInPortal={TooltipInPortal} tooltipTop={tooltipTop} tooltipLeft={tooltipLeft} tooltipData={tooltipData} unit={unit} />
			)}
		</Box>
	)
}
