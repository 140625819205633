import React, { useState } from "react"
import Pie from "@visx/shape/lib/shapes/Pie"
import { Group } from "@visx/group"
import { scaleOrdinal } from "@visx/scale"
import { animated, useTransition, to } from "@react-spring/web"

import Box from "@mui/material/Box"
import GraphTitle from "../../shared/GraphTitle"
import DonutLegend from "./sub-components/DonutLegend"
import { useEffect } from "react"
import { useTheme } from "@mui/system"

// Data Format
// [{key: , value: }]

export default function Donut({
	barColors,
	data = [],
	title = null,
	subTitle = null,
	donutFloat = "left",
	containerWidth = 800,
	containerHeight = 450,
	unit = "",
	DonutLegendProps,
	colorsTransform = null,
}) {
	const [hoverKey, setHoverKey] = useState(null)
	const [firstRender, setFirstRender] = useState(true)
	const theme = useTheme()

	const innerWidth = containerWidth
	const halfWidth = innerWidth / 2
	let innerHeight = containerHeight
	let radius = (Math.min(halfWidth, innerHeight) / 2) * 0.85

	const centerY = innerHeight / 2
	let centerX = donutFloat === "left" ? radius : innerWidth - radius
	let donutThickness = radius / 4

	// Responsiveness
	const isMobile = innerWidth <= 300
	if (isMobile) {
		radius = Math.min(innerWidth, innerHeight) / 2
		centerX = containerWidth / 2
		donutThickness = radius / 3
	}

	const domain = data.map((el) => el.key)
	const getColor = scaleOrdinal({
		domain,
		range: colorsTransform ? colorsTransform(barColors, domain) : barColors,
	})

	const getValueByKey = (key) => {
		return data.find((x) => x.key === key).value
	}

	let total = data.reduce((t, obj) => obj.value + t, 0)

	useEffect(() => {
		if (firstRender) setTimeout(() => setFirstRender(false), 500)
	})

	const outerBoxStyleMobile = { position: "static", width: innerWidth }
	const outerBoxStyleRegular = {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		float: "right",
		height: "100%",
		position: "absolute",
		left: donutFloat === "left" ? halfWidth : 0,
		width: halfWidth,
		top: centerY - radius,
	}

	return (
		<Box className="position-relative" width={containerWidth}>
			<GraphTitle title={title} subTitle={subTitle} />

			{data.length ? (
				<Box className="position-relative">
					<svg data-testid={"graph-container"} width={containerWidth} height={containerHeight}>
						<Group top={centerY} left={centerX} data-testid={"donut-position"}>
							<Pie data={data} pieValue={(el) => el.value} outerRadius={radius} innerRadius={radius - donutThickness} cornerRadius={1} padAngle={0.012}>
								{(pie) => {
									return (
										<AnimatedDonut
											{...pie}
											hoverKey={hoverKey}
											animate={firstRender}
											getKey={(arc) => `${arc.data.value}-${arc.data.label}`}
											getColor={(arc) => getColor(arc.data.key)}
											onClickDatum={setHoverKey}
										/>
									)
								}}
							</Pie>
						</Group>
						{hoverKey !== null && (
							<text className="capitalize font-size-12 opacity-80" fill={theme.palette.text.secondary} x={centerX} y={centerY - 10} textAnchor="middle">
								{hoverKey}:
							</text>
						)}
						<text fill={theme.palette.text.secondary} data-testid={"donut-total"} x={centerX} y={centerY + 8} textAnchor="middle">
							{hoverKey !== null ? getValueByKey(hoverKey).toLocaleString("en") : total.toLocaleString("en")} {unit}
						</text>
					</svg>
					<DonutLegend
						setHoverKey={setHoverKey}
						hoverKey={hoverKey}
						outerBoxStyle={isMobile ? outerBoxStyleMobile : outerBoxStyleRegular}
						data={data}
						unit={unit}
						getColor={getColor}
						total={total}
						parentWidth={containerWidth}
						{...DonutLegendProps}
					/>
				</Box>
			) : (
				<Box className="font-weight-bold display-flex align-center justify-center" height={containerHeight}>
					No data provided to BlueHalo
				</Box>
			)}
		</Box>
	)
}

const fromLeaveTransition = ({ endAngle }) => ({
	// enter from 360° if end angle is > 180°
	startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	opacity: 0,
})
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
	startAngle,
	endAngle,
	opacity: 1,
})

function AnimatedDonut({ animate, arcs, path, getKey, getColor, onClickDatum, hoverKey }) {
	const transitions = useTransition(arcs, {
		from: animate ? fromLeaveTransition : enterUpdateTransition,
		enter: enterUpdateTransition,
		update: enterUpdateTransition,
		leave: animate ? fromLeaveTransition : enterUpdateTransition,
		keys: getKey,
	})
	return transitions((props, arc, { key }) => {
		return (
			<g key={key}>
				<animated.path
					// compute interpolated path d attribute from intermediate angle values
					d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
						path({
							...arc,
							startAngle,
							endAngle,
						})
					)}
					data-testid={"donut-segment"}
					fill={hoverKey !== null ? (hoverKey === arc.data.key ? getColor(arc) : "lightgrey") : getColor(arc)}
					onMouseOver={() => onClickDatum(arc.data.key)}
					onMouseLeave={() => onClickDatum(null)}
				/>
			</g>
		)
	})
}
