import { useFilterAtomValue } from "../../hooks/FilterHooks"
import { usePartnerAtomValue } from "../../hooks/PartnerHooks"

export const setQueryParameters = (method = "GET") => {
	const filterVal = useFilterAtomValue()
	const partnerVal = usePartnerAtomValue()

	if (partnerVal.includes("Clear"))
		return {
			dateFrom: filterVal.dateFrom,
			dateTo: filterVal.dateTo,
			useActivityDate: filterVal.useActivityDate
		}

	const hasPartners = Object.entries(partnerVal).length
	const response = {
		dateFrom: filterVal.dateFrom,
		dateTo: filterVal.dateTo,
		useActivityDate: filterVal.useActivityDate
	}

	if (hasPartners) {
		if (method == "POST") {
			response.partnerCodes = partnerVal.split(",")
		} else {
			response.partners = partnerVal
		}
	}

	return response
}
