import { 
	EMISSIONS_OVERVIEW_ENDPOINT, 
	EMISSIONS_AIRBYCLASS_ENDPOINT, 
	EMISSIONS_ACCOMMBYSTAR_ENDPOINT, 
	EMISSIONS_ACCOMMBYCOUNTRY_ENDPOINT, 
	EMISSIONS_ROADBYRENTALDAYS_ENDPOINT,
	EMISSIONS_ROADBYACRISS_ENDPOINT,
	LATEST_API_VERSION } from "../../../util/Constants"
import { getAllEmissionsByGroupForDonut } from "../../../lib/convertions/EmissionsDataSet"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import { palette } from "../../../theme"
import { useModeList } from "../../../hooks/ModeHook"
import Donut from "../../ChartsVisX/ChartType/Donut/Donut"
import { setQueryParameters } from "../../../pages/sub-components/SetQueryParameters"

const GroupByDonut = ({ modeOverride = null, groupBy, title, DonutProps }) => {
	const modes = useModeList()
	const { partners } = setQueryParameters()

	let emission_endpoint = EMISSIONS_OVERVIEW_ENDPOINT
	let emission_groupName = "emissions.total"
	
	switch(groupBy){
		case "travelclass" : 
			emission_endpoint = EMISSIONS_AIRBYCLASS_ENDPOINT
			emission_groupName = "travelClass"
		break
		case "starrating" : 
			emission_endpoint = EMISSIONS_ACCOMMBYSTAR_ENDPOINT
			emission_groupName = "starRating"
		break
		case "country" : 
			emission_endpoint = EMISSIONS_ACCOMMBYCOUNTRY_ENDPOINT
			emission_groupName = "country"
		break
		case "rentalDays" : 
			emission_endpoint = EMISSIONS_ROADBYRENTALDAYS_ENDPOINT
			emission_groupName = "rentalDays"
		break
		case "acrisCode" :  
			emission_endpoint = EMISSIONS_ROADBYACRISS_ENDPOINT
			emission_groupName = "acrissCategory"
		break
	}

	const queryData = { method: "get", url: emission_endpoint, params: { groupBy, "api-version": LATEST_API_VERSION, partners } }

	return (
		<VisxChartWrapper queryData={queryData} dataTransformFunction={(data) => getAllEmissionsByGroupForDonut(data.data[modeOverride], emission_groupName, "emissions", modeOverride ? modeOverride : modes)}>
			{({ transformedData, width }) => {
				return (
					<Donut
						{...{
							...DonutProps,
							containerWidth: width,
							containerHeight: (6 * width) / 11,
							barColors: palette.charts.colorScale,
							title: title,
							subTitle: null,
							unit: "tCO2e",
							data: transformedData,
							showTotals: true,
						}}
					/>
				)
			}}
		</VisxChartWrapper>
	)
}
export default GroupByDonut
