import { BAR_CHART, getChartOptions, PIE_CHART, setChartOptions } from "./HiChartHelper"
import { palette } from "../theme"

export const seriesOptionsPie = {
	dataLabels: {
		enabled: true,
		align: "center",
		format: "<b>{point.name}</b><br>{point.y:,.0f} t/CO2e <br>{point.percentage:.1f}%",
	},
}

export const CH6_Net_Position = {
	yTitle: "Tonnes CO2e",
	type: BAR_CHART,
	drilldown: false,
	chart: {
		height: "500px",
	},
	title: "Offsets & retirements",
	exporting: {
		enabled: true,
		sourceHeight: 500,
		sourceWidth: 1200,
	},
	config: [
/*		{
			seriesName: "Net position",
			config: {
				type: "line",
				color: palette.charts.colorScale[2],
				tooltip: {
					headerFormat: `<span style="color:${palette.charts.colorScale[2]}">\u25CF</span><span> <b>Net Position is {point.y:,.3f} at this point</b></span>`,
					pointFormat: "<br>We typically do retirements quarterly<br/>so if this value seems high, its usually<br/>because you are approaching the end</br>of a quarter.<br/>",
				},
			},
		}, */
		{
			seriesName: "Retirements",
			config: {
				stack: "Offsets",
				color: palette.charts.colorScale[1],
				tooltip: {
					headerFormat: `<span style="color:${palette.charts.colorScale[1]}">\u25CF</span><span> <b>Offsets Retired</b></span>`,
					pointFormat: "<br>We retired <b>{point.y:,.0f}</b> tonnes of carbon<br/>credits. Your certificates will be available<br/>on the designated page</br>",
				},
			},
		},
	],
}

export const CH9_UN_SDG = {
	title: "UN sustainable development goals coverage",
	yTitle: "Number of projects",
	type: BAR_CHART,
	drilldown: false,
	chart: {
		height: "500px",
	},
	tooltip: {
		headerFormat: "<b>{point.custom.number}</b>",
		pointFormat:
			"<b>Goal {point.custom.number}. {point.custom.description}</b><br /><br /><b>{point.y:,.0f}</b> {point.custom.tooltipText}<br /> positive impact on people and the planet.<br />{point.custom.projects}",
	},

	column: {
		stacking: "normal",
		borderWidth: 0,
		pointPadding: 0,
		groupPadding: 0,
		pointWidth: 50,
		depth: 80,
		opacity: 0.77,
	},

	xAxis: {
		tickmarkPlacement: "on",
		lineWidth: 0,
		type: "category",
		labels: {
			useHTML: true,
			align: "center",
		},
	},
	exporting: {
		enabled: false,
	},
}

export const VEHICLE_EMISSIONS = {
	title: "Vehicle Emissions by ACRISS Car Classification",
	yTitle: "Y Title",
	type: PIE_CHART,
	drilldown: true,

	tooltip: {
		headerFormat: "<span> ACRISS Car Classification: <b>{point.key}</b></span>",
		pointFormat: "<br>This car classification is responsible for <br><b>{point.y:,.0f}</b> tonnes CO2e for the total of<br/> <b>{point.total:,.0f}</b> accross all classifications.",
	},

	seriesOptions: seriesOptionsPie,
}

export function getChartConfig(chart) {
	switch (chart) {
		//PAGE 2
		case "ch6":
			return setChartOptions(CH6_Net_Position, getChartOptions())

		default:
			return getChartOptions()
	}
}
