import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import { styled } from "@mui/material/styles"
import * as React from "react"

import BlueHalo from "../../assets/logos/bluehalo-logo-dark.png"
import DrawerItems from "./DrawerItems"
import { useState } from "react"
import { Avatar, Divider, Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Chip, Typography } from "@mui/material"

import { IcCerts } from "../../icon/IcCerts"
import { IcReport } from "../../icon/IcReport"
import { IcRescue } from "../../icon/IcRescue"
import { AiOutlineProject } from "react-icons/ai"
import { FiHome } from "react-icons/fi"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import { useAtomValue } from "jotai"
import { userAtom } from "../../atoms/user"
import { useMsal } from "@azure/msal-react"
import { IcLogout } from "../../icon/IcLogout"

import { useFlags } from "launchdarkly-react-client-sdk"

import styles from "./index.module.scss"

const openedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
	justifyContent: "space-between",
	height: "100vh",
	position: "relative",
})

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const DrawerHeader = styled("div")(({ theme }) => ({
	padding: theme.spacing(4, 4, 2, 4),
	maxWidth: "250px",
	...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	zIndex: 1000,
	...theme.typography.semibold,

	"& .MuiList-root": { padding: 0 },

	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": { border: "none", ...openedMixin(theme) },
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": { border: "none", ...closedMixin(theme) },
	}),
}))

const MiniDrawer = () => {
	const [open, setOpen] = useState(true)
	const user = useAtomValue(userAtom)
	const firstName = user.given_name
	const lastName = user.family_name

	const { instance } = useMsal()
	const { bhEmissionsLog } = useFlags()

	const SignOutUserRedirect = () => {
		instance.logoutRedirect()
		localStorage.clear()
	}

	const sideBarBottom = [
		{
			name: "Contact Support",
			link: `mailto:help@ourbluehalo.com?subject=${firstName + " " + lastName} enquiry from BlueHalo customer portal.`,
			icon: <IcRescue />,
			external: true,
		},
	]

	const sideBarTop = [
		{
			name: "Dashboard",
			link: "/",
			icon: <FiHome />,
		},
		{
			name: "Portfolio & Projects",
			link: "/portfolio",
			icon: <AiOutlineProject />,
		},
		{
			name: "Reports",
			link: null,
			icon: <IcReport />,
			submenu: [
				{
					link: "/reports/emissions",
					name: "Emissions",
					icon: null,
				},
				{
					link: "/reports/retirements",
					name: "Retirements",
					icon: null,
				},
			],
		},
		{
			name: "Retirement Certificates",
			link: "/certificates",
			icon: <IcCerts />,
		},
	]

	if (user.isAdmin)
		sideBarTop.push({
			name: "Ground Control",
			link: "/groundcontrol",
			icon: <SupportAgentIcon />,
		})

	if (bhEmissionsLog) sideBarTop[2].submenu.push({ link: "/reports/emissions-log", name: "Emissions Log", icon: null })

	return (
		<Drawer variant="permanent" open={open}>
			<Box component={"div"} key={"top-menu"}>
				<DrawerHeader>
					<img src={BlueHalo} style={{ maxWidth: "100%" }} />
				</DrawerHeader>
				<List>
					<DrawerItems open={open} sideBarList={sideBarTop} />
				</List>
			</Box>
			<Box className="w-100-percent" component={"div"} key={"bottom-menu"}>
				<Divider className="opacity-80" />
				<List>
					<DrawerItems open={open} sideBarList={sideBarBottom} />
					<Box component={"div"} key={"logout"}>
						<ListItem className="display-block" key={"user-logout"} disablePadding>
							<ListItemButton className={styles["sidebar-button"]} onClick={SignOutUserRedirect}>
								<ListItemIcon className={styles["sidebar-icon"]}>
									<IcLogout />
								</ListItemIcon>
								<ListItemText className="font-size-16" primary={<Typography variant="navItem">Log out</Typography>} />
							</ListItemButton>
						</ListItem>
					</Box>
					<Box component={"div"} key={"user"}>
						<ListItem className={styles["sidebar-avatar-container"]} key={"user-item"} disablePadding>
							<Chip
								className="p-0 border-0"
								variant="outlined"
								avatar={<Avatar>{`${firstName.length ? firstName.slice(0, 1) : ""}${lastName.length ? lastName.slice(0, 1) : ""}`}</Avatar>}
								label={
									<Box className="m-l-5">
										<ListItemText className="txt-secondary" primary={<Typography className="font-size-16" variant="navItem">{`${firstName} ${lastName}`}</Typography>} />
									</Box>
								}
							/>
						</ListItem>
					</Box>
				</List>
			</Box>
		</Drawer>
	)
}

export default MiniDrawer
