import { usePageTitle } from "../hooks/TitleHook"

import LayoutWrapper from "../layoutBuilder/LayoutWrapper"
import LayoutItem from "../layoutBuilder/LayoutItem"
import DashboardKPICards from "./sub-components/DashboardKPICards"
import AllEmissionsByMode from "../components/Charts/Wireups/AllEmissionsByMode"
import { useFilterAtomValue } from "../hooks/FilterHooks"

const MainChart = () => {
	return (
		<LayoutItem>
			<AllEmissionsByMode />
		</LayoutItem>
	)
}

const Home = () => {
	usePageTitle("BlueHalo - Dashboard")
	const filterDataValue = useFilterAtomValue()

	return (
		<LayoutWrapper>
			<DashboardKPICards filterDataValue={filterDataValue} />
			<MainChart />
		</LayoutWrapper>
	)
}

export default Home
