import { Box, Container, Typography } from "@mui/material"
import CertificateTable from "../components/CertificateTable"
import { usePageTitle } from "../hooks/TitleHook"

const Certificates = () => {
	usePageTitle("BlueHalo - Offset Retirement Certificates")

	return (
		<Box>
			<Container sx={{ ml: -3, mb: 1 }}>
				<Typography variant="h3" textAlign="start" className="txt-main m-b-0">
					Offset Retirement Certificates
				</Typography>
			</Container>
			<CertificateTable />
		</Box>
	)
}

export default Certificates
