import { Box, styled } from "@mui/material";

export default styled(Box)(({ theme }) => ({
	borderRadius: "8px",
	padding: theme.spacing(3),
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	background: "white",
	maxWidth: "650px",
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(3),
}));