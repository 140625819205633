import { styled } from "@mui/material"
import { useState } from "react"
import DateRangePicker from "../DateRangePicker/DatePicker"
import { Box } from "@mui/system"
import moment from "moment"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import QuickOptionPicker from "../DateRangePicker/QuickOptionPicker"
import { useFilterAtom } from "../../hooks/FilterHooks"
import { localStorageKeys, setLocalStorageValueByKey } from "../../hooks/LocalStorageHooks"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import * as React from "react"

import { useAtomValue } from "jotai"
import { userAtom } from "../../atoms/user"
import { PartnerSelector } from "../../pages/sub-components/PartnerSelector"
import { IcCalendar } from "../../icon/IcCalendar"

import ToggleButton from '../ToggleButton/ToggleButton'

const SiteHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "end",
	padding: theme.spacing(2, 5),
	marginBottom: theme.spacing(3),
	boxShadow: theme.shadows[1],
}))

const EmptyHeader = styled("div")(({ theme }) => ({
	marginBottom: theme.spacing(3),
}))

const FloatingElementContainer = styled("div")(() => ({
	background: "transparent",
	position: "relative",
	marginTop: -10,
}))

const InnerAbsoluteDiv = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 1000,
	width: "100%",
	padding: theme.spacing(0, 5),
	top: theme.spacing(-3),
}))

const HeaderInputContainer = styled("div")(({ theme }) => ({
	borderRadius: 4,
	boxShadow: theme.shadows[2],
	cursor: "pointer",
	height: "36px",
	maxWidth: "100vw",
	width: "480px",
	fontFamily: "Gilroy-SemiBold",
}))

const getHideShowFilter = (location, routeConfig) => {
	const routeDetails = routeConfig.find((x) => {
		return x.path === location.pathname
	})
	return routeDetails && routeDetails.showHeader ? routeDetails.showHeader === true : false
}

export default function Header({ routeConfig }) {
	const location = useLocation()
	const [filterData, setFilterData] = useFilterAtom()
	const [updateState, forceUpdateState] = useState({})
	const [openDatePicker, setOpenDatePicker] = useState(false)
	const [openQuickOptions, setOpenQuickOptions] = useState(false)
	const [showFilter, setShowFilter] = useState(getHideShowFilter(location, routeConfig))
	const user = useAtomValue(userAtom)

	const closeAll = () => {
		if (openDatePicker || openQuickOptions) {
			setOpenDatePicker(false)
			setOpenQuickOptions(false)
		}
	}

	const openDatePickerClick = () => {
		setOpenDatePicker(!openDatePicker)
		setOpenQuickOptions(false)
	}

	const openQuickOptionsClick = () => {
		setOpenDatePicker(false)
		setOpenQuickOptions(!openQuickOptions)
	}

	const updateFilter = (data, selectionString = null) => {
		const { dateFrom, dateTo, daysBetween = null } = data
		const newValue = { daysBetween: daysBetween !== null ? daysBetween : Math.ceil(moment(dateTo).diff(moment(dateFrom), "days")), selectionString, dateFrom, dateTo, useActivityDate: filterData.useActivityDate }

		setFilterData(newValue)
		forceUpdateState(newValue)
		setLocalStorageValueByKey(localStorageKeys.HEADER_DATE, newValue)
		if (selectionString) setOpenQuickOptions(false)
	}

	useEffect(() => {
		setShowFilter(getHideShowFilter(location, routeConfig))
	}, [location])

	return (
		<>
			{showFilter || user.isAdmin ? (
				<>
					<SiteHeader data-testid={"header"}>
						{user.isAdmin && <PartnerSelector />}
						<ToggleButton />
						<HeaderInputContainer data-testid={"header-click-area"} onClick={() => closeAll()}>
							<Box
								className="txt-align-left float-left border-r-1 p-1-theme"
								onClick={openDatePickerClick}
								data-testid={"date-picker-container"}
								height="100%"
								width="calc(100% - 180px)"
							>
								<IcCalendar className="float-left m-r-1-theme font-size-30 m-l-5" />
								{moment(filterData.dateFrom).format("MMM D, YYYY")} - {moment(filterData.dateTo).format("MMM D, YYYY")}
							</Box>
							<Box className="p-1-theme float-right opacity-90" data-testid={"date-picker-2-container"} onClick={openQuickOptionsClick} width="180px">
								<Box className="float-left" display={"inline"}>
									{filterData.selectionString ? filterData.selectionString : "Select"}
								</Box>
								<Box className="float-right" display="inline">
									{!openQuickOptions ? <ExpandMore className="p-b-4" /> : <ExpandLess className="p-b-4" />}
								</Box>
							</Box>
						</HeaderInputContainer>
					</SiteHeader>
					<FloatingElementContainer>
						<InnerAbsoluteDiv>
							<Box className="float-right z-index-10" width="35%" minWidth="475px">
								<Box style={{ float: "left", bottom: "0px" }}>
									<DateRangePicker closeCalendar={() => setOpenDatePicker(false)} dates={filterData} updateDate={updateFilter} open={openDatePicker} />
								</Box>
								<Box style={{ float: "right" }}>
									<QuickOptionPicker onClickAway={() => setOpenQuickOptions(false)} open={openQuickOptions} updateDate={updateFilter} />
								</Box>
							</Box>
						</InnerAbsoluteDiv>
					</FloatingElementContainer>
				</>
			) : (
				<EmptyHeader />
			)}
		</>
	)
}
