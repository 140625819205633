import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Modal, Fade, Backdrop, Typography, Button } from "@mui/material";
import ModalBox from  "./ModalBox";

import { EMISSIONS_LOG_ENDPOINT, LATEST_API_VERSION } from "../../util/Constants"
import { setQueryParameters } from "../../pages/sub-components/SetQueryParameters"
import { transformEmissionsLogToCSV } from "../../lib/ExportHelper"
import ExportButton from "../../components/ExportButton/ExportButton"
import { useQueryEndpoint } from "../../hooks/QueryHooks"
import { ErrorHandling } from "../../components/Error"
import JSZip from "jszip"

const ExportButtonControlWithQuery = ({ rawData, fileName, queryParams }) => {
	const paramsFromAppState = setQueryParameters()

	const { data, loaded, error } = useQueryEndpoint(EMISSIONS_LOG_ENDPOINT, null, "GET", {
		"api-version": LATEST_API_VERSION,
		pageSize: rawData.data ? rawData.data.totalRows : 99999,
		...paramsFromAppState,
		...queryParams,
	})

	const seperateCSVsPerModeAndZip = (transformedData, fileType, fileExtension, fileName) => {
		try {
			const zip = new JSZip()

			for (const mode of Object.keys(transformedData)) {
				const csvFileName = `${fileName} - ${mode}.${fileExtension}`
				zip.file(csvFileName, transformedData[mode])
			}

			zip.generateAsync({ type: "blob" }).then((content) => {
				const downloadLink = document.createElement("a")
				downloadLink.href = URL.createObjectURL(content)
				downloadLink.download = `${fileName}.zip`
				downloadLink.click()
			})
		} catch (err) {
			ErrorHandling("Error creating file")
		}
	}

	let modalText = `${data?.data?.totalRows} rows of data`

	if(queryParams.activityDateFrom !== null){
		modalText += ` for Activites dated from ${queryParams.activityDateFrom} to ${queryParams.activityDateTo}`
	}else{
		if(queryParams.dateFrom !== null){
			modalText += ` for Transactions dated from ${queryParams.dateFrom} to ${queryParams.dateTo}`
		} else {
			modalText += " for all Transactions available"
		}
	}
	
	return (
		<>
			{loaded && 
				<Box>
					{modalText}
				</Box>
			}
			<ExportButton
				fileType="text/csv"
				fileExtension="csv"
				transformData={transformEmissionsLogToCSV}
				loading={!loaded || error}
				data={data}
				fileName={fileName}
				customDownloadFile={seperateCSVsPerModeAndZip}
			/>
		</>
	)
}

export const ExportModal = ({ open, handleClose, rawData, queryParams }) => {

    return (
        <ModalFormWrapper
            modalTitle="Download emissions log"
            onCancelClose={handleClose}
            modalFlag={open}
            rawData={rawData}
            queryParams={queryParams}
        >
            <Box
                data-testid="export-log-modal"
                className="display-grid grid-gap-1"
            >
            </Box>
        </ModalFormWrapper>
    );
}

export const ModalFormWrapper = ({ modalTitle, onCancelClose,  modalFlag = false, rawData, queryParams, children}) => {
    const testid = modalTitle.replace(/ /g, '-').toLowerCase();

    return (
        <Modal
			data-testid={`${testid}-modal`}
			open={modalFlag}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
            <Fade in={modalFlag}>
                <ModalBox style={{ maxWidth: "450px", width: "100%" }}>
                    <Box>
						<Typography data-testid={`${testid}-modal-title`} variant="h5" fontWeight={600}>
							{modalTitle}
						</Typography>
					</Box>
                    {children}
                    <ExportButtonControlWithQuery rawData={rawData} fileName={`Emissions Log`} queryParams={queryParams} />
					<Box className="align-self-end flex-row gap-1">
						<Button
							data-testid={`${testid}-close-button`}
							className="btn-outlined-primary pl-2 pr-2"
							onClick={onCancelClose}
						>
							Cancel
						</Button>
					</Box>
                </ModalBox>
            </Fade>              
        </Modal>
    );
};